import React from "react";
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import Meta from "../components/layout/Meta";
import i18n from "../../i18n";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const Tools = ()=>{

    return(
        <>
        <Navbar/>
        <Meta title={i18n.t('tools')}/>
        <PageHeader
        pageTitle={i18n.t('tools')}
        breadcrumbTextOne="Home"
        breadcrumbUrl="/"
        breadcrumbTextTwo={i18n.t('tools')}
      />

      

<div className="faq-area ptb-100 bg-f4f7fe">
        <div className="container">
          <div className="row align-items-center">
            {/*<div className="col-lg-6">
              <div className="faq-img pr-3">
                <img
                  src="/images/faq.png"
                  alt="Image"
                  width={600}
                  height={450}
                />
              </div>
            </div>*/}

            <div className="col-lg">
              <div className="faq-accordion">
                <Accordion allowZeroExpanded preExpanded={["a"]}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      FastQC
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      quality control tool for high throughput sequence data: 
                        <a href="http://www.bioinformatics.babraham.ac.uk/projects/fastqc/" target="_blank">
                        http://www.bioinformatics.babraham.ac.uk/projects/fastqc/
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="b">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Multiqc
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      A modular tool to aggregate results from FastQC
                        <a
                          href="https://multiqc.info/"
                          target="_blank"
                        >
                          https://multiqc.info/
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="c">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Trimmomatic
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      A flexible read trimming tool for Illumina NGS data
                        <a href="http://www.usadellab.org/cms/?page=trimmomatic" target="_blank">
                        http://www.usadellab.org/cms/?page=trimmomatic
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="d">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Trim Galore
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      for quality and adapter trimming
                        <a
                          href="https://www.bioinformatics.babraham.ac.uk/projects/trim_galore/"
                          target="_blank"
                        >
                          https://www.bioinformatics.babraham.ac.uk/projects/trim_galore/
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="e">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      fastp
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      for FASTQ data preprocessing, quality control, and deduplication
                        <a
                          href="https://github.com/OpenGene/fastp"
                          target="_blank"
                        >
                          https://github.com/OpenGene/fastp
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="f">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      DESq2
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      for differential expression of RNA-seq
                        <a href="https://github.com/thelovelab/DESeq2" target="_blank">
                        https://github.com/thelovelab/DESeq2
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="g">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      edgeR
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      for differential expression of RNA-seq
                        <a href="https://bioconductor.org/packages/release/bioc/html/edgeR.html" target="_blank">
                        https://bioconductor.org/packages/release/bioc/html/edgeR.html
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="h">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      DESq2
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      for differential expression of RNA-seq
                        <a href="https://github.com/thelovelab/DESeq2" target="_blank">
                        https://github.com/thelovelab/DESeq2
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="i">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Sammtools
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      for interacting with high-throughput sequencing data
                        <a href="https://www.htslib.org/" target="_blank">
                        https://www.htslib.org/
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem uuid="j">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                      Hifiasm
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                      genome assembly with Pac bio Hifi reads
                        <a href="https://hifiasm.readthedocs.io/en/latest/faq.html" target="_blank">
                        https://hifiasm.readthedocs.io/en/latest/faq.html
                        </a>
                        <br/>
                        <a href="https://github.com/chhylp123/hifiasm" target="_blank">
                        https://github.com/chhylp123/hifiasm
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    );
}

export default Tools;