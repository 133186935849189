import React from "react";
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import i18n from "../../i18n";
import Meta from "../components/layout/Meta";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

const EventsConferences = () => {
  return (
    <>
      <Navbar />
      <Meta title={i18n.t("events-conferences")} />
      <PageHeader
        pageTitle="Events & Conferences"
        breadcrumbTextOne="Home"
        breadcrumbUrl="/"
        breadcrumbTextTwo="Events & Conferences"
      />

      <div className="faq-area ptb-100 bg-f4f7fe">
        <div className="container">
          <div className="row align-items-center">
            {/*<div className="col-lg-6">
              <div className="faq-img pr-3">
                <img
                  src="/images/faq.png"
                  alt="Image"
                  width={600}
                  height={450}
                />
              </div>
            </div>*/}

            <div className="col-lg">
              <div className="faq-accordion">
                <Accordion allowZeroExpanded preExpanded={["a"]}>
                  <AccordionItem uuid="a">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        1st Asia&Pacific Bioinformatics Joint Conference:
                        October 22-25, 2024
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <a href="https://www.apbjc.asia/" target="_blank">
                          https://www.apbjc.asia/
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="b">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        ISCB-Latin America SoIBio CCBCOL Conference on
                        Bioinformatics 2024, November 12 - 15, 2024
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <a
                          href="https://www.iscb.org/index.php"
                          target="_blank"
                        >
                          https://www.iscb.org/index.php
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="c">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        23rd European Conference on Computational Biology, 16-20
                        September 2024,
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <a href="https://eccb2024.fi/" target="_blank">
                          https://eccb2024.fi/
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="d">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        A list of conferences in Europe can be found here:
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <a
                          href="https://conferenceineurope.net/topic-list/biology"
                          target="_blank"
                        >
                          https://conferenceineurope.net/topic-list/biology
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>

                  <AccordionItem uuid="e">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Conferences of the International Society for
                        Computational Biology can be found here
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <a
                          href="https://www.iscb.org/index.php"
                          target="_blank"
                        >
                          https://www.iscb.org/index.php
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                  <AccordionItem uuid="f">
                    <AccordionItemHeading>
                      <AccordionItemButton>
                        Annual conference of the American Society of Plant
                        Biology
                      </AccordionItemButton>
                    </AccordionItemHeading>

                    <AccordionItemPanel>
                      <p className="accordion-content">
                        <a href="https://aspb.org/" target="_blank">
                          https://aspb.org/
                        </a>
                      </p>
                    </AccordionItemPanel>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventsConferences;
