import React, { Component } from 'react';
import PageHeader from '../components/layout/PageHeader';
import BlogCard from '../components/blog/BlogCard';
import BlogSideBar from '../components/blog/BlogSideBar';
import Navbar from "../components/layout/Navbar";
import Meta from '../components/layout/Meta';

class Blog extends Component {
    render() {
        return (
            <React.Fragment>
                <Meta title="Blog"/>
                <Navbar />
                <PageHeader 
                    pageTitle="Blog" 
                    breadcrumbTextOne="Home" 
                    breadcrumbUrl="/" 
                    breadcrumbTextTwo="Blog" 
                />

                {/* Blog Area */}
                <div className="blog-area blog-ptb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8 col-md-12">
                                <BlogCard />
                            </div>

                            <div className="col-lg-4 col-md-12">
                                <BlogSideBar />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Blog Area */}
            </React.Fragment>
        );
    }
}

export default Blog;