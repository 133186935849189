import React from "react";
import { Route } from "react-router-dom";
import Home from "../pages/Home";
import Logout from "../components/login/Logout";
import Register from "../components/login/Register";
import Login from "../components/login/Login";
import Error404 from "../pages/Error404";
import LandingPage from "../pages/LandingPage";
import Contact from "../pages/Contact";
import Blog from "../pages/Blog";
import WorkshopsSeminars from "../pages/WorkshopsSeminars";
import Conferences from "../pages/Conferences";
import EventsConferences from "../pages/EventsConferences";
import Databases from "../pages/Databases";
import Tools from "../pages/Tools";
import Bioinformatics from "../pages/Bioinformatics";
import Tutorials from "../pages/Tutorials";
import Courses from "../pages/Courses";
import Training from "../pages/Training";
import Media from "../pages/Media";
import Books from "../pages/Books";
import Articles from "../pages/Articles";
import Publications from "../pages/Publications";
import About from "../pages/about2";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermsConditions from "../pages/TermsConditions";
import BlogDetailsContent from "../components/blog/BlogDetailsContent";
import ProductDetails from "../components/Shop/ProductDetails";
import Profile from "../pages/Profile";

const routes = [
  <Route exact path="/login" element={<Login />} key="login" />,
  <Route path="/logout" element={<Logout />} exact key="logout" />,
  <Route path="/register" element={<Register />} exact key="register" />,
  <Route path="/dashboard" element={<Home />} exact key="dashboard" />,
  <Route path="/" element={<LandingPage />} exact key="home" />,

  <Route path="/contact" element={<Contact />} exact key="contact" />,
  <Route path="/blog" element={<Blog />} exact key="blog" />,
  <Route
    path="/workshops-seminars"
    element={<WorkshopsSeminars />}
    exact
    key="workshops-seminars"
  />,
  <Route
    path="/conferences"
    element={<Conferences />}
    exact
    key="conferences"
  />,
  <Route
    path="/events-conferences"
    element={<EventsConferences />}
    exact
    key="events-conferences"
  />,
  <Route path="/databases" element={<Databases />} exact key="databases" />,
  <Route path="/tools" element={<Tools />} exact key="tools" />,
  <Route
    path="/bioinformatics"
    element={<Bioinformatics />}
    exact
    key="bioinformatics"
  />,
  <Route path="/tutorials" element={<Tutorials />} exact key="tutorials" />,
  <Route path="/courses" element={<Courses />} exact key="courses" />,
  <Route path="/christian-siadjeu" element={<Profile />} exact key="profile" />,
  <Route path="/training" element={<Training />} exact key="training" />,
  <Route path="/media" element={<Media />} exact key="media" />,
  <Route path="/books" element={<Books />} exact key="books" />,
  <Route
    path="/books/rna-made-simple"
    element={<ProductDetails />}
    exact
    key="books-details"
  />,
  <Route path="/articles" element={<Articles />} exact key="articles" />,
  <Route
    path="/publications"
    element={<Publications />}
    exact
    key="publications"
  />,
  <Route path="/about" element={<About />} exact key="about" />,
  <Route
    path="/privacy-policy"
    element={<PrivacyPolicy />}
    exact
    key={"privacy-policy"}
  />,
  <Route
    path="/terms-conditions"
    element={<TermsConditions />}
    exact
    key={"terms-conditions"}
  />,
  <Route
    path="/blog/la-maitrise-de-la-biologie-moleculaire-est-un-enjeu-crucial-pour-le-developpement-de-lafrique"
    element={<BlogDetailsContent />}
    key={
      "la-maitrise-de-la-biologie-moleculaire-est-un-enjeu-crucial-pour-le-developpement-de-lafrique"
    }
  />,
  <Route path="*" element={<Error404 />} key="error404" />,
];

export default routes;
