import React from "react";
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import i18n from "../../i18n";

const Publications = ()=>{

    return(
        <>
        <Navbar/>

        <PageHeader
        pageTitle={i18n.t('publications')}
        breadcrumbTextOne="Home"
        breadcrumbUrl="/"
        breadcrumbTextTwo={i18n.t('publications')}
      />
        </>
    );
}

export default Publications;