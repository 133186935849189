import React from "react";
import { Link } from "react-router-dom";
import BlogSideBar from "./BlogSideBar";
import i18n from "../../../i18n";
import blogImage1 from "../../assets/img/blog-image/blog-image10.jpg";
import Meta from "../layout/Meta";
import Navbar from "../layout/Navbar";
import PageHeader from "../layout/PageHeader";

const BlogDetailsContent = () => {
  return (
    <>
    <Meta title={i18n.t('blog-title-1')} desceiption={""} />
    <Navbar/>
    <PageHeader 
      pageTitle={i18n.t('blog-title-1')}
      breadcrumbTextOne="Home" 
      breadcrumbUrl="/" 
      breadcrumbTextTwo={i18n.t('blog-title-1')} 
  />
    <>
      <div className="blog-area blog-ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12">
              <div className="blog-details-desc">
                <div className="article-image">
                  <img
                    src={blogImage1}
                    alt="image"
                  />
                </div>

                <div className="article-content">
                  <div className="entry-meta">
                    <ul>
                      <li>
                        <span>Posted On:</span>
                        <Link href="#">September 16, 2024</Link>
                      </li>
                      <li>
                        <span>Posted By:</span>
                        <Link to="/christian-siadjeu">Christian Siadjeu</Link>
                      </li>
                    </ul>
                  </div>

                  <br/>

                  <p>
                  {i18n.t('blog-paragraph-a-1')}
                  </p>

                  <p>
                  {i18n.t('blog-paragraph-a-2')}
                  </p>

                  <blockquote className="wp-block-quote">
                    <p>
                    {i18n.t('blog-paragraph-quote-a-2')}
                    </p>

                    <cite>Christian Siadjeu</cite>
                  </blockquote>
                  <p dangerouslySetInnerHTML={{ __html: i18n.t('blog-paragraph-a-3') }}></p>

                  <ul className="wp-block-gallery columns-3">
                    <li className="blocks-gallery-item">
                      <figure>
                        <img
                          src="/images/blog-image/blog-image3.jpg"
                          alt="image"
                          width={860}
                          height={700}
                        />
                      </figure>
                    </li>

                    <li className="blocks-gallery-item">
                      <figure>
                        <img
                          src="/images/blog-image/blog-image4.jpg"
                          alt="image"
                          width={860}
                          height={700}
                        />
                      </figure>
                    </li>

                    <li className="blocks-gallery-item">
                      <figure>
                        <img
                          src="/images/blog-image/blog-image3.jpg"
                          alt="image"
                          width={860}
                          height={700}
                        />
                      </figure>
                    </li>
                  </ul>
                  <p>
                  {i18n.t('blog-paragraph-a-4')}
                  </p>

                </div>

                <div className="article-footer">
                  <div className="article-tags">

                  </div>

                  <div className="article-share">
                    {/*<!-- ShareThis BEGIN -->*/}
                    <div class="sharethis-inline-share-buttons"></div>
                    {/*<!-- ShareThis END -->*/}
                    {/*<ul className="social">
                      <li>
                        <span>Share:</span>
                      </li>
                      <li>
                        <a href="https://www.facebook.com/" target="_blank">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.twitter.com/" target="_blank">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/" target="_blank">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/" target="_blank">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>*/}
                  </div>
                </div>

              </div>
            </div>

            <div className="col-lg-4 col-md-12">
              <BlogSideBar />
            </div>
          </div>
        </div>
      </div>
    </>
    </>
  );
};

export default BlogDetailsContent;
