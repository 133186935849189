import React from "react";
import { Link  } from 'react-router-dom';
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { addToCart } from "../../redux/actions/cartActions";

const ShopProducts = ({ products, addToCart }) => {
  const handleAddToCart = (id) => {
    addToCart(id);
    toast.success("Added to the cart", {
      position: "bottom-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <>
      <div className="shop-area ptb-100">
        <ToastContainer />

        <div className="container">
          <div className="woocommerce-topbar">
          </div>

          <div className="row">
            {products.map((data, idx) => (
              <div className="col-lg-4 col-md-6 col-sm-6" key={idx}>
                <div className="single-product-box">
                  <div className="product-image">
                    <Link to="/books/rna-made-simple">
                      <img
                        src={require("../../assets/img/shop/rna-made-simple.jpg")} //
                        alt="image"
                        width={800}
                        height={850}
                      />
                    </Link>

                  </div>

                  <div className="product-content">
                    <h3>
                      <Link to="/books/rna-made-simple">{data.title}</Link>
                    </h3>
                    <h6>
                      <Link to="/books/rna-made-simple">{data.subtitle}</Link>
                    </h6>
                    <div className="price">
                    <span className="new">{data.price } € </span>
                    {/*  <span className="new">${data.price - 10}</span>
                      <span className="old">${data.price}</span>*/}
                    </div>

                    <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/*<div className="col-lg-12 col-md-12">
              <div className="pagination-area">
                <Link to="#" className="prev page-numbers">
                  <i className="fas fa-angle-double-left"></i>
                </Link>

                <Link to="#" className="page-numbers">
                  1
                </Link>

                <span className="page-numbers current">2</span>

                <Link to="#" className="page-numbers">
                  3
                </Link>

                <Link to="#" className="page-numbers">
                  4
                </Link>

                <Link to="#" className="next page-numbers">
                  <i className="fas fa-angle-double-right"></i>
                </Link>
              </div>
            </div>*/}


          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    products: state.auth.products,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (id) => {
      dispatch(addToCart(id));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShopProducts);
