import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../../i18n";
import blogImage from "../../assets/img/blog-image/blog-image10-510x400.jpg";

const BlogSideBar = () => {
  return (
    <>
      <aside className="widget-area" id="secondary">
        {/* Search
        <div className="widget widget_search">
          <form className="search-form">
            <label>
              <input
                type="search"
                className="search-field"
                placeholder="Search..."
              />
            </label>
            <button type="submit">
              <i className="fas fa-search"></i>
            </button>
          </form>
        </div>
 */}
        {/* Popular Posts */}
        <div className="widget widget_posts_thumb">
          <h3 className="widget-title">Popular Posts</h3>

          <article className="item">
            <Link href="/blog/details" className="thumb">
              <span
                className="fullimage cover"
                role="img"
                style={{
                  backgroundImage: `url(/images/blog-image/blog-image7.jpg)`,
                }}
              ></span>
            </Link>

            <div className="info">
              <time>June 10, 2023</time>
              <h4 className="title usmall">
                <Link to="/blog">
                  Making Peace With The Feast Or Famine Of Freelancing
                </Link>
              </h4>
            </div>

            <div className="clear"></div>
          </article>
        </div>

        {/* Categories */}
        <div className="widget widget_categories">
          <h3 className="widget-title">Categories</h3>

          <ul>
            <li>
              <Link to="/blog">Biologie</Link>
            </li>
          </ul>
        </div>

        {/* Recent Posts */}
        <div className="widget widget_recent_entries">
          <h3 className="widget-title">Recent Posts</h3>

          <ul>
            <li>
              <Link to="/blog">
                How to Become a Successful Entry Level UX Designer
              </Link>
            </li>
          </ul>
        </div>

        {/* Tags */}
        <div className="widget widget_tag_cloud">
          <h3 className="widget-title">Tags</h3>

          <div className="tagcloud">
            <Link to="/blog">
            Biologie <span className="tag-link-count"> (2)</span>
            </Link>
          </div>
        </div>
      </aside>
    </>
  );
};

export default BlogSideBar;
