import React, { Component } from 'react';
import { Link } from "react-router-dom";
import shape2 from "../../assets/img/shape/shape2.svg";
import shape3 from "../../assets/img/shape/shape3.svg";
import i18n from "../../../i18n";
import blogImage from "../../assets/img/blog-image/blog-image10-510x400.jpg";
class RecentStory extends Component {
    render() {
        return (
            <React.Fragment>
                <section className="blog-area ptb-100">
                    <div className="container">
                        <div className="section-title">
                            <h2>Our Recent Story</h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6">
                                <div className="single-blog-post">
                                    <div className="post-image">
                                        <Link to="/blog/la-maitrise-de-la-biologie-moleculaire-est-un-enjeu-crucial-pour-le-developpement-de-lafrique">
                                            <a>
                                                <img src={blogImage} 
                                                alt="image"
                                                width={416}
                                                height={326}
                                                 />
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="post-content">
                                        <div className="post-meta">
                                            <ul>
                                                <li>
                                                    By: 
                                                    <Link to="/christian-siadjeu">
                                                        <a>Christian Siadjeu</a>
                                                    </Link>
                                                </li>
                                                <li>September 16, 2024</li>
                                            </ul>
                                        </div>
                                        <h3>
                                            <Link to="/blog/la-maitrise-de-la-biologie-moleculaire-est-un-enjeu-crucial-pour-le-developpement-de-lafrique">
                                                <a>{i18n.t('blog-title-1').substring(0, 53)}...</a> 
                                            </Link>
                                        </h3>
                                        <p>{i18n.t('blog-paragraph-a-1').substring(0, 123)}...</p>

                                        <Link to="/blog/la-maitrise-de-la-biologie-moleculaire-est-un-enjeu-crucial-pour-le-developpement-de-lafrique">
                                            <a className="read-more-btn">
                                                Read More <i className="flaticon-right-arrow"></i>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6">
                                <div className="single-blog-post">
                                    <div className="post-image">
                                        <Link href="/blog-details">
                                            <a>
                                                <img src={require("../../assets/img/blog-image/blog-image11.jpg")} alt="image" />
                                            </a>
                                        </Link>
                                    </div>
                                    <div className="post-content">
                                        <div className="post-meta">
                                            <ul>
                                                <li>By: 
                                                    <Link href="#">
                                                        <a>James Anderson</a>
                                                    </Link>
                                                </li>
                                                <li>June 26, 2019</li>
                                            </ul>
                                        </div>
                                        <h3>
                                            <Link href="/blog-details">
                                                <a>The Rise Of Smarketing And Why You Need It</a>
                                            </Link>
                                        </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>

                                        <Link href="/blog-details">
                                            <a className="read-more-btn">
                                                Read More <i className="flaticon-right-arrow"></i>
                                            </a>
                                        </Link>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* Animation Shape Image */}
                    <div className="shape-img2">
                        <img src={shape2} alt="image" />
                    </div>
                    <div className="shape-img3">
                        <img src={shape3} alt="image" />
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default RecentStory;