import React from "react";
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import ShopProducts from "../components/Shop/ShopProducts";
import i18n from "../../i18n";
import Meta from "../components/layout/Meta";

const Books = ()=>{

    return(
        <>
        <Navbar/>
        <Meta title={i18n.t('books')}/>
        <PageHeader
        pageTitle={i18n.t('books')}
        breadcrumbTextOne="Home"
        breadcrumbUrl="/"
        breadcrumbTextTwo={i18n.t('books')}
      />
 <ShopProducts />

        </>
    );
}

const mapStateToProps = (state) => {
    return {
      products: state.addedItems,

    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {

    };
  };

export default Books;