import React, { Component } from 'react';
//import Navbar from '../components/Layout/Navbar';
import PageHeader from '../components/layout/PageHeader';
import ContactForm from '../components/Contact/ContactForm';
import ContactInfo from '../components/Contact/ContactInfo';
import Navbar from "../components/layout/Navbar";
import Meta from '../components/layout/Meta';
class Contact extends Component {
    render() {
        return (
            <React.Fragment>
                <Navbar /> 
                <Meta title="Contact"/>
                <PageHeader 
                    pageTitle="Contact" 
                    breadcrumbTextOne="Home" 
                    breadcrumbUrl="/" 
                    breadcrumbTextTwo="Contact" 
                />
                 
                <ContactForm />
                <ContactInfo />
            </React.Fragment>
        );
    }
}

export default Contact;