import React from "react";
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import AboutArea from "../components/AboutTwo/AboutArea";
import Meta from "../components/layout/Meta";
import i18n from "../../i18n";

export default function About2() {
  return (
    <>
      <Navbar />
      <Meta title={i18n.t('about')}/>
      <PageHeader
        pageTitle={i18n.t('about')}
        breadcrumbTextOne="Home"
        breadcrumbUrl="/"
        breadcrumbTextTwo={i18n.t('about')}
      />

      <AboutArea />

      {/*<FunFactsTwo />

      <WhyChooseUs />

      <Solution />

       <Testimonials /> */}
{/*
      <hr />

      <OurTeamTwo />

      <SubscribeStyleThree />

       <PartnerWithTitle /> */}
      
      {/* <Footer /> */}
    </>
  );
}
