import React from "react";
import { connect } from "react-redux";
import Banner from "../components/layout/Banner";
import FeaturedSolutions from "../components/layout/FeaturedSolutions";
import RecentStory from "../components/layout/RecentStory";
import FunFacts from "../components/layout/FunFacts";
import Meta from "../components/layout/Meta";
import i18n from "../../i18n";
import NavbarTwo from "../components/layout/NavbarTwo";

function LandingPage() {
  return (
    <React.Fragment>
      <Meta
        title={i18n.t("home-title")}
        description={i18n.t("home-page-title")}
      />
      <NavbarTwo />
      <Banner />
      <FeaturedSolutions />
      <FunFacts />
      <RecentStory />
      {/*<SubscribeBoxedTwo/>*/}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  user: state.user.show.retrieved,
  balance: state.user.show.retrieved?.accounts,
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(LandingPage);
