import React, { Component } from 'react';
import { Link } from "react-router-dom";
import shape2 from "../../assets/img/shape/shape2.svg";
import shape3 from "../../assets/img/shape/shape3.svg";
import shape4 from "../../assets/img/shape/shape4.png";
import shape5 from "../../assets/img/shape/shape5.png";
import shape6 from "../../assets/img/shape/shape6.png";
import shape7 from "../../assets/img/shape/shape7.png";
import shape8 from "../../assets/img/shape/shape8.png";
import shape9 from "../../assets/img/shape/shape9.png";
import shape10 from "../../assets/img/shape/shape10.png";
import i18n from "../../../i18n";

class Banner extends Component {
    render() {
        return (
<>
<div
  className="portfolio-agency-banner"
  style={{
    backgroundImage: "../../assets/img/portfolio-agency/pa-slider3.jpg",
  }}
>
  <div className="d-table">
    <div className="d-table-cell">
      <div className="container mt-80">
        <div className="banner-content text-center">
          <h1
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="100"
          >
            {i18n.t('home-page-title')}
          </h1>

          <p
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            {i18n.t('home-page-text')}
          </p>

          <div
            className="banner-btn"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            <Link to="/contact" className="default-btn mr-4">
            {i18n.t('contact-us')} <span></span>
            </Link>
            {/* 
            <Link to="/index9" className="default-btn-two">
            {i18n.t('try-it-free')} <span></span>
            </Link>*/}
          </div>
        </div>
      </div>
    </div>
  </div>

  {/* Shape Images */}
  <div className="shape-img2">
  <img src={shape2}
      alt="image"
      width={20}
      height={20}
    />
  </div>
  <div className="shape-img3">
  <img src={shape3}
      alt="image"
      width={22}
      height={22}
    />
  </div>
  <div className="shape-img4">
  <img src={shape4}
      alt="image"
      width={15}
      height={15}
    />
  </div>
  <div className="shape-img5">
  <img src={shape5}
      alt="image"
      width={18}
      height={18}
    />
  </div>
  <div className="shape-img6">
  <img src={shape6}
      alt="image"
      width={202}
      height={202}
    />
  </div>
  <div className="shape-img7">
  <img src={shape7}
      alt="image"
      width={100}
      height={93}
    />
  </div>
  <div className="shape-img8">
  <img src={shape8}
      alt="image"
      width={74}
      height={64}
    />
  </div>
  <div className="shape-img9">
  <img src={shape9}
      alt="image"
      width={43}
      height={46}
    />
  </div>
  <div className="shape-img10">
    <img src={shape10} alt="image" 
        width={12}
        height={11}
        />
  </div>
</div>
</>

        );
    }
}

export default Banner;