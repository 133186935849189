import React, { Component } from 'react';
import PageHeader from '../components/layout/PageHeader';
import Navbar from "../components/layout/Navbar";
import Meta from '../components/layout/Meta';
import i18n from '../../i18n';

class TermsConditions extends Component {
    render() {
        return (
            <React.Fragment>
                <Meta title={i18n.t("terms-conditions")}/>
                <Navbar /> 
                <PageHeader 
                    pageTitle="Terms Conditions" 
                    breadcrumbTextOne="Home" 
                    breadcrumbUrl="/" 
                    breadcrumbTextTwo="TermsConditions" 
                />
                 

            </React.Fragment>
        );
    }
}

export default TermsConditions;