import { toast } from "react-toastify";

const initialState = {
  isLoggedIn: false,
  TokenPayload: {},
  accessToken: "",
  token: null,
  loginRequired: false,
  refreshing: false,
  loginLoading: false,
  refreshToken: null,
  savePath: null,
  overlayLoading: false,
  isAuthenticated: false,
  abortController: {},
  isRegister: false,
  isConfirm: false,
  uuid:"",

    questionnaires: [],
    currentQuestionnaireIndex: 0,
    currentQuestionIndex: 0,
    score: 0,
    userAnswers: [],
    
    addedItems: [],
    total: 0,
    shipping: 0,
    products: [
      {
          id: 1,
          title: "RNA Made Simple",
          subtitle: "The Essential Guide to Extraction",
          price: 29.00,
          image: "../../assets/img/shop/item1.jpg",
          imageHover: "../../assets/img/shop/item1-hover.jpg"
      }
  ]
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOAD_REFRESH":
      return { ...state, refreshing: true };

    case "LOGIN_SUCCESS":
      return {
        ...state,
        token: action.payload.token,
        accessToken: action.accessToken,
        refreshToken: action.payload.refresh_token,
        TokenPayload: action.payload,
        isAuthenticated: true,
        loginRequired: false,
        isLoggedIn: true,
      };
    case "REGISTER_SUCCESS":
      return {
        ...state,
        isRegister: action.payload,
      };

    case "EMAIL_CONFIRM":
      return {
        ...state,
        isConfirm: action.payload,
      };

    case "REFRESHING_TOKEN":
      return {
        ...state,
        isLoggedIn: true,
        accessToken: action.accessToken,
        refreshing: false,
        TokenPayload: action.payload,
      };

    case "LOGOUT":
      return initialState;

    case "LOGIN_LOADING":
      return { ...state, loginLoading: action.payload };

    case "OVERLAY_LOADING":
      return { ...state, overlayLoading: action.payload };

    case "LAUNCH_MODAL_LOGIN":
      return { ...state, loginRequired: action.payload };

    case "SAVE_CURRENT_PATH":
      return { ...state, savePath: action.payload };

    case "TRANSACTION_UUID": 
      return { ...state, uuid: action.payload }; 

    case "SEND_TOAST":
      if (action.state === "success")
        toast.success(action.payload, action.options);
      else if (action.state === "error")
        toast.error(action.payload, action.options);
      else if (action.state === "warn")
        toast.warn(action.payload, action.options);
      else if (action.state === "info")
        toast.info(action.payload, action.options);
      else toast(action.payload);

      return { ...state };

    case "ABORT":
      return {
        ...state,
        abortController: {
          ...state.abortController,
          [action.name]: new AbortController(),
        },
      };


      case 'NEXT_QUESTION':
        return {
          ...state,
          currentQuestionIndex: state.currentQuestionIndex + 1,
        };

      case 'LOAD_QUESTIONNAIRES':
        return { ...state, questionnaires: action.payload };
      case 'ANSWER_QUESTION':
        const { currentQuestionnaireIndex, currentQuestionIndex } = state;
        const currentQuestionnaire = state.questionnaires[currentQuestionnaireIndex];
        const currentQuestion = currentQuestionnaire.questions[currentQuestionIndex];
        const isAnswerCorrect = action.payload === currentQuestion.correctAnswer;
        return {
          ...state,
          score: isAnswerCorrect ? state.score + 1 : state.score,
          userAnswers: [...state.userAnswers, { questionnaireIndex: currentQuestionnaireIndex, questionIndex: currentQuestionIndex, isAnswerCorrect }],
          currentQuestionIndex: currentQuestionIndex + 1,
        };

    default:
      return state;
  }
};

export default authReducer;
