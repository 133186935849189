import React, { useState } from "react";
import { Link } from 'react-router-dom';
import i18n from "../../../i18n";

const ProductsDetailsTab = () => {
  const [currentTab, setCurrentTab] = useState("tab3");

  const openTabSection = (tabName) => {
    setCurrentTab(tabName);
  };

  return (
    <div className="tab products-details-tab">
      <div className="row">
        <div className="col-lg-12 col-md-12">
          <ul className="tabs">
            <li
              onClick={() => openTabSection("tab1")}
              className={currentTab === "tab1" ? "current" : ""}
            >
              <span>
                <div className="dot"></div> Description
              </span>
            </li>
            <li
              onClick={() => openTabSection("tab3")}
              className={currentTab === "tab3" ? "current" : ""}
            >
              <span>
                <div className="dot"></div> Reviews
              </span>
            </li>
          </ul>
        </div>

        <div className="col-lg-12 col-md-12">
          <div className="tab_content">
            <div
              id="tab1"
              className={`tabs_item ${
                currentTab === "tab1" ? "fadeInUp animated" : ""
              }`}
            >
              <div className="products-details-tab-content"
              dangerouslySetInnerHTML={{ __html: i18n.t("rna-made-simple-description")}} >
              </div>
            </div>

            <div
              id="tab2"
              className={`tabs_item ${
                currentTab === "tab2" ? "fadeInUp animated" : ""
              }`}
            >
              <div className="products-details-tab-content">
                <ul className="additional-information">
                  <li>
                    <span>Brand:</span> ThemeForest
                  </li>
                  <li>
                    <span>Color:</span> Brown
                  </li>
                  <li>
                    <span>Size:</span> Large, Medium
                  </li>
                  <li>
                    <span>Weight:</span> 27 kg
                  </li>
                  <li>
                    <span>Dimensions:</span> 16 x 22 x 123 cm
                  </li>
                </ul>
              </div>
            </div>

            <div
              id="tab3"
              className={`tabs_item ${
                currentTab === "tab3" ? "fadeInUp animated" : ""
              }`}
            >
              <div className="products-details-tab-content">
                <div className="product-review-form">
                  <h3>Customer Reviews</h3>

                  <div className="review-title">
                    <div className="rating">
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="fas fa-star"></i>
                      <i className="far fa-star"></i>
                    </div>
                    <p>Based on 3 reviews</p>

                    <Link href="#" className="default-btn">
                      Write a Review <span></span>
                    </Link>
                  </div>

                  <div className="review-comments">
                    <div className="review-item">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="far fa-star"></i>
                      </div>
                      <h3>Good</h3>
                      <span>
                        <strong>Admin</strong> on <strong>Sep 21, 2023</strong>
                      </span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation.
                      </p>

                      <Link href="#" className="review-report-link">
                        Report as Inappropriate
                      </Link>
                    </div>

                    <div className="review-item">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="far fa-star"></i>
                      </div>
                      <h3>Good</h3>
                      <span>
                        <strong>Admin</strong> on <strong>Sep 21, 2023</strong>
                      </span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation.
                      </p>

                      <Link href="#" className="review-report-link">
                        Report as Inappropriate
                      </Link>
                    </div>

                    <div className="review-item">
                      <div className="rating">
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="fas fa-star"></i>
                        <i className="far fa-star"></i>
                      </div>
                      <h3>Good</h3>
                      <span>
                        <strong>Admin</strong> on <strong>Sep 21, 2023</strong>
                      </span>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                        sed do eiusmod tempor incididunt ut labore et dolore
                        magna aliqua. Ut enim ad minim veniam, quis nostrud
                        exercitation.
                      </p>

                      <Link href="#" className="review-report-link">
                        Report as Inappropriate
                      </Link>
                    </div>
                  </div>

                  <div className="review-form">
                    <h3>Write a Review</h3>

                    <form>
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Enter your name"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-lg-6 col-md-6">
                          <div className="form-group">
                            <input
                              type="email"
                              id="email"
                              name="email"
                              placeholder="Enter your email"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              id="review-title"
                              name="review-title"
                              placeholder="Enter your review a title"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <div className="form-group">
                            <textarea
                              name="review-body"
                              id="review-body"
                              cols="30"
                              rows="7"
                              placeholder="Write your comments here"
                              className="form-control"
                            ></textarea>
                          </div>
                        </div>

                        <div className="col-lg-12 col-md-12">
                          <button type="submit" className="default-btn">
                            Submit Review <span></span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsDetailsTab;
