import React from "react";
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import i18n from "../../i18n";
import Meta from "../components/layout/Meta";

const Conferences = ()=>{

    return(
        <>
        <Meta title={i18n.t('conferences')} />
        <Navbar/>
        <PageHeader
        pageTitle={i18n.t('conferences')}
        breadcrumbTextOne="Home"
        breadcrumbUrl="/"
        breadcrumbTextTwo={i18n.t('conferences')}
      />

        </>
    );
}

export default Conferences;