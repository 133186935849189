import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
//import defaultOgImage from '../../assets/img/og-image.jpg';
//import { withRouter } from 'react-router';

class Meta extends React.Component {

    render() {
        const url = window.location.href;
        const title = this.props.title ? (Array.isArray(this.props.title) ? this.props.title.join(' - ') : this.props.title) + ' - ' : '';
        const description = this.props.description ? this.props.description : '';
        //const ogImage = this.props.ogImage ? this.props.ogImage : window.location.origin + defaultOgImage;
        return (
            <>
                <Helmet>
                    <title>{title}CS Molbio</title>
                    {description && <meta name="description" content={description}/>}
                    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"/>
                    <meta name="robots" content="noindex, nofollow"/>
                    <meta property="og:type" content="webApp"/>
                    <meta property="og:url" content={url}/>
                    <meta name="og:title" content={title}/>
                    {description && <meta name="og:description" content={description}/>}

                {/*     <meta name="og:image" content={ogImage}/> */}

                </Helmet>
                <Helmet>
                    {this.props.children}
                </Helmet>
            </>
        );

    }

}

Meta.propTypes = {
    title: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    description: PropTypes.string,
    ogImage: PropTypes.string,
    children: PropTypes.object,
};


export default Meta;
