import React from "react";

const Loader = ({ loading }) => {
  return (
    <>
      <div className={`preloader ${loading ? "" : "preloader-deactivate"}`}>
        <div className="loader">
          <div className="shadow"></div>
          <div className="box"></div>
        </div>
      </div>
    </>
  );
};

export default Loader;
