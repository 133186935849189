import React from "react";
import { connect } from "react-redux";
import { addQuantityWithNumber } from "../../redux/actions/cartActions";
import { ToastContainer } from "react-toastify";
import ProductsDetailsTab from "./ProductsDetailsTab";
import Navbar from "../layout/Navbar";
import Meta from "../layout/Meta";

const ProductDetails = ({ addQuantityWithNumber }) => {

  return (
    <>
      <Navbar />
      <Meta title={"RNA Made Simple"} />
      <div className="product-details-area ptb-100">
        <ToastContainer />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="product-details-image">
                <img
                  src={require("../../assets/img/shop/rna-made-simple.jpg")}
                  alt="image"
                  width={800}
                  height={850}
                />
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="product-details-desc">
                <h3>RNA Made Simple</h3>

                <div className="price">
                  <span className="new-price">29 €</span>
                </div>

                <div className="buy-checkbox-btn">
                  <div className="item">
                    <a
                      href="https://www.logos-verlag.com/cgi-bin/engbuchmid?isbn=5807&lng=eng&id="
                      className="btn btn-light"
                      target="_blank"
                    >
                      Buy it now!
                    </a>
                  </div>
                  {/*<!-- ShareThis BEGIN -->*/}
                  <div class="sharethis-inline-share-buttons"></div>
                  {/*<!-- ShareThis END -->*/}
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              {/* Products Details Tab */}
              <ProductsDetailsTab />
            </div>
          </div>
        </div>

        {/* Related Products 
        <RelatedProducts />*/}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addQuantityWithNumber: (id, qty) => {
      dispatch(addQuantityWithNumber(id, qty));
    },
  };
};

export default connect(null, mapDispatchToProps)(ProductDetails);
