import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import i18n from '../../../i18n';

class ContactForm extends Component {

    sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_ho19uxr', 'template_jrzu6z4', e.target, 'your_user_id')
            .then((result) => {
                console.log(result.text);
                alert("Message sent successfully!");
            }, (error) => {
                console.log(error.text);
                alert("Failed to send the message, please try again.");
            });

        e.target.reset();
    }

    render() {
        return (
            <section className="contact-area ptb-100">
                <div className="container">
                    <div className="section-title">
                        <span className="sub-title">Contact Us</span>
                        <h2>Drop us a Message for any Query</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-lg-4 col-md-4">
                            <div className="contact-image">
                                <img src={require("../../assets/img/contact.png")} alt="image" />
                            </div>
                        </div>

                        <div className="col-lg-8 col-md-8">
                            <div className="contact-form">
                                <form id="contactForm" onSubmit={this.sendEmail}>
                                    <div className="row">
                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input type="text" name="name" className="form-control" placeholder="Name" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input type="email" name="email" className="form-control" placeholder="Email" required />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input type="text" name="phone_number" className="form-control" placeholder="Phone" />
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-12">
                                            <div className="form-group">
                                                <input type="text" name="subject" className="form-control" placeholder="Subject" />
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" className="form-control" rows="5" placeholder="Your Message" required></textarea>
                                            </div>
                                        </div>

                                        <div className="col-lg-12 col-md-12 text-center">
                                            <button type="submit" className="default-btn">
                                                Send Message <span></span>
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ContactForm;
