import React from "react";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import {
  faMoneyBill,
  faQrcode,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
import AlertDismissible from "../components/utils/AlertDismissible";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Home = ({ user, isLoggedIn, balance }) => {

   if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }
   
  return (
   <div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="profile-user-box card-box bg-custom">
                <div className="row">
                  <div className="col-sm-6">
                    <span className="float-left mr-3">
                      <img
                        src="https://bootdey.com/img/Content/avatar/avatar1.png"
                        alt=""
                        className="thumb-lg rounded-circle"
                      />
                    </span>
                    <div className="media-body text-white">
                      <h4 className="mt-1 mb-1 font-18">
                        {" "}
                        {user?.firstName} {user?.lastName}
                      </h4>
                      <p className="text-light mb-0">California, United States</p>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="text-right">
                      <Link to="/edit-profile">
                        <button type="button" className="btn btn-light waves-effect">
                          <i className="mdi mdi-account-settings-variant mr-1"></i>{" "}
                          Edit Profile
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              {!user?.mobileVerify && <AlertDismissible variant="warning" />}
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4">
              <div className="card-box">
                <h4 className="header-title mt-0">Personal Information</h4>
                <div className="panel-body">
                  <p className="text-muted font-13">
                    Hye, I’m Johnathan Doe residing in this beautiful world.
                  </p>
                  <hr />
                  <div className="text-left">
                    <p className="text-muted font-13">
                      <strong>Full Name :</strong>{" "}
                      <span className="m-l-15">{user?.firstName} {user?.lastName}</span>
                    </p>
                    <p className="text-muted font-13">
                      <strong>Mobile :</strong>
                      <span className="m-l-15">{user?.mobilePhone}</span>
                    </p>
                    <p className="text-muted font-13">
                      <strong>Email :</strong>{" "}
                      <span className="m-l-15">{user?.email}</span>
                    </p>
                    <p className="text-muted font-13">
                      <strong>Location :</strong>{" "}
                      <span className="m-l-15">USA</span>
                    </p>
                  </div>

                </div>
              </div>

              <div className="card-box ribbon-box">
                <div className="ribbon ribbon-primary">Messages</div>
                <div className="clearfix"></div>
                <div className="inbox-widget">
                  <a href="#/">
                    <div className="inbox-item">
                      <div className="inbox-item-img">
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar2.png"
                          className="rounded-circle"
                          alt=""
                        />
                      </div>
                      <p className="inbox-item-author">Tomaslau</p>
                      <p className="inbox-item-text">
                        I've finished it! See you so...
                      </p>
                      <p className="inbox-item-date">
                        <button
                          type="button"
                          className="btn btn-icon btn-sm waves-effect waves-light btn-success"
                        >
                          Reply
                        </button>
                      </p>
                    </div>
                  </a>
                  <a href="#/">
                    <div className="inbox-item">
                      <div className="inbox-item-img">
                        <img
                          src="https://bootdey.com/img/Content/avatar/avatar3.png"
                          className="rounded-circle"
                          alt=""
                        />
                      </div>
                      <p className="inbox-item-author">Stillnotdavid</p>
                      <p className="inbox-item-text">This theme is awesome!</p>
                      <p className="inbox-item-date">
                        <button
                          type="button"
                          className="btn btn-icon btn-sm waves-effect waves-light btn-success"
                        >
                          Reply
                        </button>
                      </p>
                    </div>
                  </a>
                </div>
              </div>
            </div>



            <div className="col-xl-8">

            <div className="col-sm-12 ">
              <Link to="/quiz">
                <div className="card-box tilebox-one bg-primary text-white">
                  <i className="mdi mdi-account-settings-variant mr-1"></i>{" "}
                  Start Quiz
                </div>
              </Link>
            </div>

              <div className="row">
                {user?.roles && (user.roles.includes('ROLE_SUPER_ADMIN') || user.roles.includes('ROLE_PROF') || user.roles.includes('ROLE_ADMIN'))? 
                <div className="col-sm-4">
                <Link to="/operations">
                  <div className="card-box tilebox-one">
                    <i className="icon-layers float-right text-muted"></i>
                    <h6 data-plugin="counterup">
                      Balance
                      </h6>
                    <h2  data-plugin="counterup">
                    {balance ? balance[0].balance: 0} Fcfa
                      </h2>
                      <h6 className="text-muted text-uppercase mt-0">
                    <FontAwesomeIcon icon={faMoneyBill} /></h6>
                    <span className="text-muted"></span>
                  </div>
                  </Link>
                </div>
                : <></> }
                <div className="col-sm-4">
                <Link to="/generate-qr">
                  <div className="card-box tilebox-one">
                    <i className="icon-paypal float-right text-muted"></i>
                    <h2 data-plugin="counterup">
                      <FontAwesomeIcon icon={faQrcode} />
                      </h2>
                      <h2 data-plugin="counterup">
                      Show QR
                      </h2>
                      <span className="badge badge-custom"></span>
                    <span className="text-muted"></span>
                  </div>
                  </Link>
                </div>

                <div className="col-sm-4">
                  <Link to="/scan-qr">
                    <div className="card-box tilebox-one ">
                      <i className="icon-rocket float-right text-muted"></i>
                      <h2 data-plugin="counterup">
                      <FontAwesomeIcon icon={faCamera} />
                      </h2>
                      <h2 data-plugin="counterup">
                        Scan QR
                      </h2>
                      <span className="badge badge-custom"></span>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="card-box">
                <h4 className="header-title mb-3">Operations</h4>
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Description</th>
                        <th>Assign</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td>01/01/2023</td>
                        <td>2500 Fcfa</td>
                        <td>Adminox Admin</td>
                        <td>Audrey</td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>01/02/2023</td>
                        <td>2500 Fcfa</td>
                        <td>Adminox Frontend</td>
                        <td>Valentina</td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>01/01/2015</td>
                        <td>2500 Fcfa</td>
                        <td>Adminox Admin</td>
                        <td>Paule</td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>01/01/2015</td>
                        <td>2500 Fcfa</td>
                        <td>Adminox Admin</td>
                        <td>Zoe</td>
                      </tr>

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.show.retrieved,
  balance: state.user.show.retrieved?.accounts,
  isLoggedIn: state.auth.isLoggedIn,
});

export default connect(mapStateToProps)(Home);
