import React from "react";
import Navbar from "../components/layout/Navbar";
import PageHeader from "../components/layout/PageHeader";
import i18n from "../../i18n";
import Meta from "../components/layout/Meta";

const Articles = ()=>{

    return(
        <>
        <Navbar/>
        <Meta title={i18n.t('articles')}/>
        <PageHeader
        pageTitle={i18n.t('articles')}
        breadcrumbTextOne="Home"
        breadcrumbUrl="/"
        breadcrumbTextTwo={i18n.t('articles')}
      />
        </>
    );
}

export default Articles;